import * as React from 'react';
import { useEffect } from 'react';
import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../core/core.types';
import { actions } from '../../core/state/actions';
import AssessmentsHeader from '../../components/assessments/assessments-header/AssessmentsHeader';
import AssessmentsSidebar from '../../components/assessments/assessments-sidebar/AssessmentsSidebar';
import { IOpportunityItem, IPatientInfo } from '../../core/models/assessments/assessments.models';
import { IAttachmentsListItem, INotesListItem } from '../../core/models/notes/notes.models';

function AssessmentsPage() {
	const dispatch = useAppDispatch();
	const { opportunityId } = useParams<{ opportunityId: string }>();
	const opportunity = useAppSelector<IOpportunityItem | undefined>((state) => state.assessments.opportunity);
	// const opportunityIsLoading = useAppSelector<boolean>((state) => state.assessments.opportunityIsLoading);
	const patientInfo = useAppSelector<IPatientInfo | undefined>((state) => state.assessments.patientInfo);
	const patientInfoIsLoading = useAppSelector<boolean>((state) => state.assessments.patientInfoIsLoading);
	const notes = useAppSelector<INotesListItem[]>((state) => state.assessments.notes);
	const notesIsLoading = useAppSelector<boolean>((state) => state.assessments.notesIsLoading);
	const attachments = useAppSelector<IAttachmentsListItem[]>((state) => state.assessments.attachments);
	const attachmentsIsLoading = useAppSelector<boolean>((state) => state.assessments.attachmentsIsLoading);
	const attachmentPreviewBlob = useAppSelector<Blob | undefined>((state) => state.assessments.attachmentPreview);
	const attachmentDownloadBlob = useAppSelector<Blob | undefined>((state) => state.assessments.attachmentDownload);
	const blobIsLoading = useAppSelector<boolean>((state) => state.assessments.attachmentIsLoading);
	const notesAndAttachmentsIsLoading = notesIsLoading || attachmentsIsLoading;

	const onOpenAttachment = (id: string, preview: boolean, download: boolean) => {
		dispatch(
			actions.assessments.getAttachmentAction({
				id,
				preview,
				download,
			})
		);
	};

	useEffect(() => {
		if (opportunityId && opportunity?.['@id'] !== opportunityId) {
			dispatch(actions.assessments.getOpportunityAction({ id: opportunityId }));
		}
	}, [opportunityId]);

	return (
		<Box
			sx={{
				flexGrow: 1,
				bgcolor: 'background.paper',
				width: '100%',
				display: 'flex',
				flexDirection: 'column',
			}}
		>
			<AssessmentsHeader isLoading={patientInfoIsLoading} patientInfo={patientInfo} opportunity={opportunity} />
			<AssessmentsSidebar
				notes={notes}
				attachments={attachments}
				notesAndAttachmentsIsLoading={notesAndAttachmentsIsLoading}
				onOpenAttachment={onOpenAttachment}
				attachmentPreviewBlob={attachmentPreviewBlob}
				attachmentDownloadBlob={attachmentDownloadBlob}
				blobIsLoading={blobIsLoading}
			/>
		</Box>
	);
}

export default AssessmentsPage;
