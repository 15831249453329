import * as React from 'react';
import { useState } from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import DataTable from '../../components/data-table/DataTable';
import { AssessmentHeadings, IAssessmentItem } from '../../core/models/assessments/assessments.models';
import { push } from '../../system/navigator';
import { useAppDispatch } from '../../core/core.types';
import { actions } from '../../core/state/actions';

export default function Assessments() {
	const dispatch = useAppDispatch();
	const [assessmentsData, setAssessmentsData] = useState<IAssessmentItem[]>([]);
	const [loading, setLoading] = useState<boolean>(false);
	const columns = Object.values(AssessmentHeadings).map((x) => ({ name: x }));

	const rows = assessmentsData.map((item) => [
		{
			value: item.name,
		},
		{
			value: item.scheduledDate,
		},
		{
			value: item.submittedDate,
		},
		{
			value: item.stage,
		},
		{
			value: item.schedulingStatus,
		},
		{
			value: item.phone,
		},
		{
			value: item.shippingStreet,
		},
		{
			value: item.shippingCity,
		},
		{
			value: item.shippingState,
		},
		{
			value: item.accountManager,
		},
		{
			value: item.territory,
		},
		{
			value: item.referringName,
		},
		{
			value: item.referringAccount,
		},
		{
			value: item.keyInstructions,
		},
	]);
	const handleRowClick = (idx: number) => {
		const assessment = assessmentsData[idx];
		push(`/assessment/${assessment.id}`);
	};

	React.useEffect(() => {
		dispatch(actions.assessments.getAssessmentsListAction());
	}, []);
	return (
		<Box sx={{ mt: 2, p: '15px', width: '100%' }}>
			<Typography variant="h3" sx={{ mb: 2, lineHeight: '1.1' }}>
				My Assessments{assessmentsData ? `(${assessmentsData.length})` : ''}
			</Typography>
			{loading ? (
				<Box sx={{ display: 'flex', flex: 1, justifyContent: 'center' }}>
					<CircularProgress />
				</Box>
			) : (
				<DataTable columns={columns} rows={rows} hover onRowClick={handleRowClick} />
			)}
		</Box>
	);
}
