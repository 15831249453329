import React, { ReactNode } from 'react';
import { Box, SxProps, Theme } from '@mui/material';
import Header from '../header/Header';

interface IPageContainerProps {
	children: ReactNode;
	sx?: SxProps<Theme>;
}

function PageContainer({ children, sx }: IPageContainerProps) {
	return (
		<div>
			<Header />
			<Box
				sx={{
					display: 'flex',
					minHeight: 'calc(100vh - 64px)',
					flex: 1,
					...sx,
				}}
			>
				{children}
			</Box>
		</div>
	);
}

PageContainer.defaultProps = {
	sx: undefined,
};

export default PageContainer;
