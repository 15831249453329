import * as React from 'react';
import { useState } from 'react';
import {
	Box,
	Button,
	CircularProgress,
	ListItemIcon,
	ListItemText,
	MenuItem,
	MenuList,
	Typography,
	useTheme,
} from '@mui/material';
import AttachmentIcon from '@mui/icons-material/Attachment';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import NoteOutlinedIcon from '@mui/icons-material/NoteOutlined';
import DropdownButton from '../../buttons/DropdownButton';
import { IAttachmentsListItem, INotesListItem } from '../../../core/models/notes/notes.models';
import NotesAttachmentsDialog from './NotesAttachmentsDialog';

interface INotesAttachmentsProps {
	notes: INotesListItem[];
	attachments: IAttachmentsListItem[];
	attachmentPreviewBlob?: Blob;
	attachmentDownloadBlob?: Blob;
	blobIsLoading: boolean;
	loading: boolean;
	onOpenAttachment: (id: string, preview: boolean, download: boolean) => void;
}

export default function NotesAttachments({
	notes,
	attachments,
	attachmentPreviewBlob,
	attachmentDownloadBlob,
	blobIsLoading,
	loading,
	onOpenAttachment,
}: INotesAttachmentsProps) {
	const theme = useTheme();
	const [dialogOpen, setDialogOpen] = useState(false);
	const [openedNoteItem, setOpenedNoteItem] = useState<null | INotesListItem>(null);
	const [openedAttachmentItem, setOpenedAttachmentItem] = useState<null | IAttachmentsListItem>(null);

	const handleUploadAFile = () => {
		console.log('Upload a File');
	};

	const handleTakeAPhoto = () => {
		console.log('Take a Photo');
	};

	const handleOpenNote = (note: INotesListItem) => {
		setDialogOpen(true);
		setOpenedNoteItem(note);
	};

	const handleOpenAttachment = (attachment: IAttachmentsListItem) => {
		onOpenAttachment(attachment.id, true, false);
		setOpenedAttachmentItem(attachment);
		setDialogOpen(true);
	};

	const handleDownloadAttachment = (attachmentId: string) => {
		onOpenAttachment(attachmentId, false, true);
	};

	const handleNotesDialogClose = () => {
		setDialogOpen(false);
		setOpenedAttachmentItem(null);
		setOpenedNoteItem(null);
	};

	const addAFileOptions = [
		{
			label: 'Upload a File',
			onClick: handleUploadAFile,
		},
		{
			label: 'Take a Photo',
			onClick: handleTakeAPhoto,
		},
	];

	return (
		<div>
			<Box sx={{ p: 2, borderTop: 1, borderColor: theme.palette.divider }}>
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'center',
						mb: 2,
					}}
				>
					<Button variant="outlined" sx={{ mr: 2 }} size="small">
						Add a Note
					</Button>
					<DropdownButton label="Add a File" options={addAFileOptions} />
				</Box>
				<Typography variant="h4" color="primary">
					Notes & Attachments
				</Typography>
				{loading ? (
					<Box sx={{ display: 'flex', justifyContent: 'center', pt: 2 }}>
						<CircularProgress />
					</Box>
				) : (
					<MenuList>
						{attachments.map((attachment) => (
							<MenuItem key={attachment.id} onClick={() => handleOpenAttachment(attachment)}>
								<ListItemIcon>
									<AttachmentIcon fontSize="small" />
								</ListItemIcon>
								<ListItemText
									primary={attachment.title}
									sx={{ fontSize: '12px', overflow: 'hidden' }}
									disableTypography
								/>
								<ListItemIcon sx={{ justifyContent: 'right' }}>
									<InfoOutlinedIcon fontSize="small" />
								</ListItemIcon>
							</MenuItem>
						))}
						{notes.map((note) => (
							<MenuItem key={note.sfid} onClick={() => handleOpenNote(note)}>
								<ListItemIcon>
									<NoteOutlinedIcon fontSize="small" />
								</ListItemIcon>
								<ListItemText primary={note.title} sx={{ fontSize: '12px', overflow: 'hidden' }} disableTypography />
								<ListItemIcon sx={{ justifyContent: 'right' }}>
									<InfoOutlinedIcon fontSize="small" />
								</ListItemIcon>
							</MenuItem>
						))}
					</MenuList>
				)}
			</Box>
			<NotesAttachmentsDialog
				open={dialogOpen}
				onClose={handleNotesDialogClose}
				onDownload={handleDownloadAttachment}
				openedNoteItem={openedNoteItem}
				openedAttachmentItem={openedAttachmentItem}
				attachmentPreviewBlob={attachmentPreviewBlob}
				attachmentDownloadBlob={attachmentDownloadBlob}
				blobIsLoading={blobIsLoading}
			/>
		</div>
	);
}
