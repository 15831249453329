export function acronymFilter(fullName: string | undefined): string {
	// Clean empty spaces from name, get initials and add them over the image
	if (!fullName) {
		return 'JD';
	}
	const nameParts = fullName.trim().replace(/\s+/g, ' ').split(' ');

	let initials = '';
	if (nameParts.length === 1) {
		initials = nameParts[0].trim();
	} else if (nameParts.length >= 2) {
		initials = nameParts[0].trim()[0] + nameParts[nameParts.length - 1].trim()[0];
	}

	return initials.toUpperCase();
}
