interface IProtectedRouteProps {
	children: JSX.Element;
	token: string | null;
}

function ProtectedRoute({ children, token }: IProtectedRouteProps): JSX.Element | null {
	if (!token) {
		return null;
	}
	return children;
}

export default ProtectedRoute;
