export const routes: {
	home: string;
	homeTabs: {
		calendar: string;
		assessments: string;
	};
	patientAssessment: string;
	login: string;
} = {
	home: '/',
	login: '/login',
	homeTabs: {
		calendar: '/calendar',
		assessments: '/assessments',
	},
	patientAssessment: '/assessment',
};
