import { environment } from '../../environment/environment';

const { API_URL, MYPAT_URL } = environment;

export const endpoints = {
	myPAT: {
		userToken: `${MYPAT_URL}/user-token`,
		login: `${MYPAT_URL}/login`,
	},
	assessment: {
		list: `${API_URL}/assessment/list`,
		patientInfo: `${API_URL}/assessment/patient_info`,
		notes: `${API_URL}/assessment/notes`,
		attachments: `${API_URL}/assessment/attachments`,
	},
	salesforce: {
		opportunities: `${API_URL}/salesforce/opportunities`,
		me: `${API_URL}/me`,
	},
};
