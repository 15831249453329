import * as env from './env.json';

export type EnvType = 'dev' | 'stage' | 'prod';

interface IEnvConfig {
	ENV: EnvType;
	API_URL: string;
	MYPAT_URL: string;
	DECRYPTION_KEY: string;
	SALESFORCE_URL: string;
}

export const environment: IEnvConfig = {
	...env,
} as IEnvConfig;
