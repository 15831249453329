import * as React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, styled } from '@mui/material';

const StyledTableCellHeader = styled(TableCell)(() => ({
	textTransform: 'uppercase',
	fontSize: '12px',
	lineHeight: '17px',
	fontWeight: 700,
	color: '#777',
	padding: '12px',
	border: '1px solid #d6d6d6',
}));

const StyledTableCellRow = styled(TableCell)(() => ({
	padding: '12px',
	fontSize: '14px',
	color: '#333',
	lineHeight: '20px',
	fontWeight: 700,
	border: '1px solid #d6d6d6',
	cursor: 'pointer',
}));

interface IDataTableProps {
	columns: Array<{ name: string }>;
	rows?: Array<IDataTableRow>[];
	dataHiddenColumnIndices?: number[];
	onRowClick?: (rowIndex: number) => void;
	hover?: boolean;
}

export interface IDataTableRow {
	value: React.ReactNode;
}

export default function DataTable({ columns, dataHiddenColumnIndices, rows, hover, onRowClick }: IDataTableProps) {
	const additionalProps = (index: number) =>
		dataHiddenColumnIndices && dataHiddenColumnIndices.find((x) => x === index) !== undefined
			? { 'data-private': true }
			: {};
	return (
		<TableContainer component={Paper}>
			<Table sx={{ minWidth: 650 }} aria-label="simple table">
				<TableHead>
					<TableRow>
						{columns.map(
							(x, index) =>
								index !== 0 && <StyledTableCellHeader key={x.name}>{columns[index].name}</StyledTableCellHeader>
						)}
					</TableRow>
				</TableHead>
				<TableBody>
					{rows &&
						rows.map((row, rowIndex) => (
							<TableRow hover={hover} onClick={() => onRowClick && onRowClick(rowIndex)}>
								{row.map((x, index) => (
									<StyledTableCellRow
										// eslint-disable-next-line react/no-array-index-key
										key={index}
										// eslint-disable-next-line react/jsx-props-no-spreading
										{...additionalProps(index)}
									>
										{row[index].value}
									</StyledTableCellRow>
								))}
							</TableRow>
						))}
				</TableBody>
			</Table>
		</TableContainer>
	);
}
