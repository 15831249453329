import React, { useLayoutEffect, useState } from 'react';
import { BrowserRouterProps, Router } from 'react-router-dom';
import { BrowserHistory } from 'history';
import { navigator } from '../../system/navigator';

interface Props extends BrowserRouterProps {
	history: BrowserHistory;
}

function CustomRouter({ basename, history, children }: Props) {
	const [state, setState] = useState({
		action: history.action,
		location: history.location,
	});
	useLayoutEffect(() => history.listen(setState), [history]);
	return (
		<Router navigator={navigator} location={state.location} basename={basename}>
			{children}
		</Router>
	);
}

export default CustomRouter;
