import * as React from 'react';
import { useRoutes } from 'react-router-dom';
import { routes } from './routes';
import Assessments from '../Assessments/Assessments';
import AssessmentsPage from '../Assessments/AssessmentsPage';
import ProtectedRoute from '../../components/router/ProtectedRoute';
import { getBearerCache } from '../../system/local-storage';

export default function Router() {
	const token = getBearerCache();
	return useRoutes([
		{
			path: `${routes.home}`,
			element: (
				<ProtectedRoute token={token}>
					<Assessments />
				</ProtectedRoute>
			),
			children: [
				{
					path: `${routes.home}/:tab`,
					element: (
						<ProtectedRoute token={token}>
							<Assessments />
						</ProtectedRoute>
					),
				},
			],
		},
		{
			path: `${routes.patientAssessment}/:opportunityId/:step`,
			element: (
				<ProtectedRoute token={token}>
					<AssessmentsPage />
				</ProtectedRoute>
			),
		},
	]);
}
