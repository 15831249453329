import { Action } from 'redux';
import { appActions } from './app/app.actions';
import { assessmentsActions } from './assessments/assessments.actions';
import { userActions } from './user/user.actions';

export interface IAction<T> extends Action {
	payload: T;
}

export const actions = {
	app: appActions,
	assessments: assessmentsActions,
	user: userActions,
};
