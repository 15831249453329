import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Box, Tabs, Tab, Grid, CircularProgress } from '@mui/material';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import PeopleIcon from '@mui/icons-material/People';
import { styled } from '@mui/system';

import PageContainer from '../../components/page-container/PageContainer';
import ThemeProvider from '../../theme';
import Router from './Router';
import './styles/App.css';
import { useAppDispatch } from '../../core/core.types';
import { actions } from '../../core/state/actions';

import { navigator, replace } from '../../system/navigator';
import { routes } from './routes';

interface TabPanelProps {
	children?: React.ReactNode;
	index: number;
	value: number;
}

function TabPanel(props: TabPanelProps) {
	const { children, value, index } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`vertical-tabpanel-${index}`}
			aria-labelledby={`vertical-tab-${index}`}
			style={value === index ? { width: '100%', display: 'flex' } : {}}
		>
			{value === index && <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>{children}</Box>}
		</div>
	);
}

const StyledTabs = styled(Tabs)(({ theme }) => ({
	backgroundColor: theme.palette.primary.main,
	padding: '10px 0 0',
}));

const StyledTab = styled(Tab)(({ theme }) => ({
	'&.Mui-selected .MuiSvgIcon-root': {
		color: '#48b977',
	},
	'& .MuiSvgIcon-root': {
		color: theme.palette.secondary.main,
	},
}));

function App() {
	const dispatch = useAppDispatch();
	const currentPath = navigator.location.pathname;
	const [tabValue, setTabValue] = useState(currentPath !== routes.homeTabs.assessments ? 1 : 0);

	const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
		setTabValue(newValue);
		if (newValue) {
			replace(routes.homeTabs.assessments);
		} else {
			replace(routes.homeTabs.calendar);
		}
	};

	useEffect(() => {
		dispatch(actions.app.initAppAction());
	}, []);

	useEffect(() => {
		if (currentPath === routes.homeTabs.assessments || currentPath === routes.home) {
			setTabValue(1);
			dispatch(actions.assessments.getAssessmentsListAction());
		}
		if (currentPath === routes.homeTabs.calendar) {
			setTabValue(0);
		}
	}, [currentPath]);

	return (
		<ThemeProvider>
			<PageContainer>
				<Box
					sx={{
						flexGrow: 1,
						bgcolor: 'background.paper',
						display: 'flex',
						flexDirection: 'row',
					}}
				>
					<StyledTabs
						orientation="vertical"
						value={tabValue}
						onChange={handleTabChange}
						textColor="inherit"
						indicatorColor="secondary"
					>
						<StyledTab icon={<CalendarMonthIcon />} aria-label="calendar" />
						<StyledTab icon={<PeopleIcon />} aria-label="assessments" />
					</StyledTabs>

					<TabPanel value={tabValue} index={0}>
						<Grid container sx={{ mt: 2 }}>
							<CircularProgress />
						</Grid>
					</TabPanel>

					<TabPanel value={tabValue} index={1}>
						<Router />
					</TabPanel>
				</Box>
			</PageContainer>
		</ThemeProvider>
	);
}

export default connect()(App);
