export const assessmentsConstants = {
	GET_ASSESSSMENTS_LIST: 'HOME/GET_ASSESSMENTS_LIST',
	GET_OPPORTUNITY: 'ASSESSMENT/GET_OPPORTUNITY',
	GET_OPPORTUNITY_IS_LOADING: 'ASSESSMENT/GET_OPPORTUNITY_IS_LOADING',
	SET_OPPORTUNITY: 'ASSESSMENT/SET_OPPORTUNITY',

	GET_PATIENT_INFO: 'ASSESSMENT/GET_PATIENT_INFO',
	GET_PATIENT_INFO_IS_LOADING: 'ASSESSMENT/GET_PATIENT_INFO_IS_LOADING',
	SET_PATIENT_INFO: 'ASSESSMENT/SET_PATIENT_INFO',

	GET_NOTES_LIST: 'ASSESSMENT/GET_NOTES_LIST',
	GET_NOTES_LIST_IS_lOADING: 'ASSESSMENT/GET_NOTES_LIST_IS_LOADING',
	SET_NOTES_LIST: 'ASSESSMENT/SET_NOTES_LIST',

	GET_ATTACHMENTS_LIST: 'ASSESSMENT/GET_ATTACHMENTS_LIST',
	GET_ATTACHMENTS_LIST_IS_LOADING: 'ASSESSMENT/GET_ATTACHMENTS_LIST_IS_LOADING',
	SET_ATTACHMENTS_LIST: 'ASSESSMENT/SET_ATTACHMENTS_LIST',

	GET_ATTACHMENT: 'ASSESSMENT/GET_ATTACHMENT',
	GET_ATTACHMENT_IS_LOADING: 'ASSESSMENT/GET_ATTACHMENT_IS_LOADING',
	SET_ATTACHMENT_PREVIEW: 'ASSESSMENT/SET_ATTACHMENT_PREVIEW',
	SET_ATTACHMENT_DOWNLOAD: 'ASSESSMENT/SET_ATTACHMENT_DOWNLOAD',
};
