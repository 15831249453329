import { createReducer } from '@reduxjs/toolkit';
import { userActions } from './user.actions';

export interface IUserState {
	name: string;
	username: string;
}

const initialState: IUserState = {
	name: '',
	username: '',
};

const userReducer = createReducer(initialState, (builder) => {
	builder.addCase(userActions.setUsernameAction, (state, action) => {
		state.name = action.payload.name;
		state.username = action.payload.username;
	});
});

export default userReducer;
