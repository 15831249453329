import { createAction } from '@reduxjs/toolkit';
import { userConstants } from './user.constants';

export interface ISetUsernameActionPayload {
	name: string;
	username: string;
}

const setUsernameAction = createAction<ISetUsernameActionPayload>(userConstants.SET_USERNAME);

export const userActions = {
	setUsernameAction,
};
