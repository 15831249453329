import { IOpportunityItem, IPatientInfo } from '../../models/assessments/assessments.models';
import { getRequest } from '../api-service';
import { endpoints } from '../endpoints';

export function getAssessmentsList(): Promise<any> {
	const endpoint = endpoints.assessment.list;
	return getRequest<any>(endpoint, {}, {});
}

export function getPatientInfo(request: { opportunitySfid: string }): Promise<IPatientInfo> {
	const body = {
		opportunitySfid: request.opportunitySfid,
	};
	const endpoint = endpoints.assessment.patientInfo;
	return getRequest<IPatientInfo>(endpoint, body);
}

export function getSfOpportunities(request: { id: string }): Promise<IOpportunityItem> {
	const body = {
		id: request.id,
	};
	const endpoint = endpoints.salesforce.opportunities;
	return getRequest<IOpportunityItem>(endpoint, body);
}
