// Bearer
export function setBearerCache(token: string): void {
	localStorage.setItem('bearer', token);
}

export function getBearerCache(): string | null {
	return localStorage.getItem('bearer');
}

export function deleteBearerCache(): void {
	localStorage.removeItem('bearer');
}

// Authenticated
export function setIsAuthenticatedCache(isAuthenticated: boolean) {
	localStorage.setItem('isAuthenticated', isAuthenticated.toString());
}

export function getIsAuthenticatedCache(): boolean {
	return localStorage.getItem('isAuthenticated') === 'true';
}

export function deleteIsAuthenticatedCache(): void {
	localStorage.removeItem('isAuthenticated');
}
