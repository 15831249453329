import React from 'react';
import { CssBaseline } from '@mui/material';
import {
	Experimental_CssVarsProvider as CssVarsProvider,
	experimental_extendTheme as extendTheme,
} from '@mui/material/styles';

declare module '@mui/material/styles' {
	interface PaletteOptions {
		border: {
			main?: string;
		};
	}
	interface Palette {
		border: {
			main: string;
		};
	}
}

interface IThemeProviderProps {
	children: React.ReactNode;
}

const theme = extendTheme({
	colorSchemes: {
		light: {
			palette: {
				primary: {
					main: '#2c1f52',
				},
				secondary: {
					main: '#543680',
				},
				border: {
					main: '#d6d6d6',
				},
			},
		},
	},
	typography: {
		fontFamily: 'Source Sans Pro, sans-serif',
		fontSize: 16,
		fontWeightLight: 300,
		fontWeightRegular: 500,
		fontWeightMedium: 600,
		fontWeightBold: 700,
		caption: {
			fontWeight: 700,
		},
		h3: {
			color: '#333',
			fontWeight: 600,
			lineHeight: '1.1',
			marginBottom: '7px',
			fontSize: '18px',
		},
		h4: {
			marginTop: '20px',
			fontSize: '18px',
			fontWeight: 600,
			lineHeight: '25px',
		},
	},
});

export default function ThemeProvider({ children }: IThemeProviderProps) {
	return (
		<CssVarsProvider theme={theme}>
			<CssBaseline />
			{children}
		</CssVarsProvider>
	);
}
