import { AnyObject } from '../../shared/types';
import { getBearerCache } from '../../system/local-storage';

const PATH_PARAMS = ['id', 'sfid', 'hash', 'opportunitySfid'];

function queryParams(params: AnyObject, ignoreIdPath: boolean): any {
	let path = '';
	const parameters = Object.entries(params)
		.filter(([key, value]) => value !== '')
		.map(([key, value]) => {
			if (PATH_PARAMS.includes(key) && !ignoreIdPath) {
				path += `/${value}`;
				return '';
			}
			return `${key}=${value}`;
		})
		.filter((param) => param !== '')
		.join('&');

	return { path, query: parameters ? `?${parameters}` : '' };
}

function fetchEndpoint(
	endpoint: string,
	method: string,
	body: AnyObject,
	additionalHeaders: AnyObject = {}
): Promise<Response> {
	const bearerToken = getBearerCache();
	const authorization = bearerToken && { authorization: `Bearer ${bearerToken}` };
	let ignoreIdPath = false;

	if (additionalHeaders.ignoreIdPath) {
		ignoreIdPath = true;
		/* eslint-disable-next-line no-param-reassign */
		delete additionalHeaders.ignoreIdPath;
	}

	const headers = {
		Accept: 'application/json',
		'Content-Type': 'application/json',
		...authorization,
		...additionalHeaders,
	};

	let requestProps: RequestInit = {};

	const { path, query } = queryParams(body, ignoreIdPath);

	let newEndpoint = endpoint;

	if (method === 'POST' || method === 'PUT' || method === 'PATCH') {
		requestProps = {
			method,
			headers,
			body: JSON.stringify(body),
		};
	} else if (method === 'GET' || method === 'DELETE') {
		requestProps = {
			method,
			headers,
		};
		newEndpoint = `${newEndpoint}${path}${query}`;
	}

	return fetch(newEndpoint, requestProps);
}

async function request<T>(method: string, endpoint: string, body: AnyObject, headers: AnyObject): Promise<T> {
	const response: Response = await fetchEndpoint(endpoint, method, body, headers);

	return response.json();
}

export async function blobRequest(method: string, endpoint: string, body: AnyObject, headers: AnyObject): Promise<any> {
	const response: Response = await fetchEndpoint(endpoint, method, body, headers);

	return response.blob();
}

export async function getBlobRequest(endpoint: string, body: AnyObject = {}, headers: AnyObject = {}): Promise<Blob> {
	return blobRequest('GET', endpoint, body, headers);
}

export async function postRequest<T>(endpoint: string, body: AnyObject = {}, headers: AnyObject = {}): Promise<T> {
	return request('POST', endpoint, body, headers);
}

export async function putRequest<T>(endpoint: string, body: AnyObject = {}, headers: AnyObject = {}): Promise<T> {
	return request('PUT', endpoint, body, headers);
}

export async function getRequest<T>(endpoint: string, body: AnyObject = {}, headers: AnyObject = {}): Promise<T> {
	return request('GET', endpoint, body, headers);
}

export async function deleteRequest<T>(endpoint: string, body: AnyObject = {}, headers: AnyObject = {}): Promise<T> {
	return request('DELETE', endpoint, body, headers);
}
