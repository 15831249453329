import * as CryptoJS from 'crypto-js';
import { IGetAuthenticatedUsernameResponse } from '../core/models/salesforce/salesforce.models';

export function decrypt(message: string, key: string) {
	const json = JSON.parse(CryptoJS.enc.Utf8.stringify(CryptoJS.enc.Base64.parse(message)));

	const salt = CryptoJS.enc.Hex.parse(json.salt);
	const iv = CryptoJS.enc.Hex.parse(json.iv);

	const encrypted = json.ciphertext;

	let iterations = parseInt(json.iterations, 10);
	if (iterations <= 0) {
		iterations = 999;
	}

	const encryptMethod = 'AES-256-CBC';
	const aesNumber = encryptMethod.match(/\d+/)?.[0] ?? '256';
	const encryptMethodLength = parseInt(aesNumber, 10) / 4;
	const hashKey = CryptoJS.PBKDF2(key, salt, {
		hasher: CryptoJS.algo.SHA512,
		keySize: encryptMethodLength / 8,
		iterations,
	});

	const decrypted = CryptoJS.AES.decrypt(encrypted, hashKey, { mode: CryptoJS.mode.CBC, iv });

	return decrypted.toString(CryptoJS.enc.Utf8);
}

export function checkExpiredJWT(response: IGetAuthenticatedUsernameResponse): boolean {
	return response.message?.includes('JWT') || false;
}
