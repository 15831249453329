import { call, fork, put } from 'redux-saga/effects';
import { getAssessmentsList, getPatientInfo, getSfOpportunities } from '../../api/assessments/assessments.api';
import { handleGlobalException } from '../../../application/exception-handlers';
import { IAction, actions } from '../actions';
import { IGetAttachmentActionPayload, IGetOpportunityActionPayload } from './assessments.actions';
import { IOpportunityItem, IPatientInfo } from '../../models/assessments/assessments.models';
import { IAttachmentsListItem, INotesListItem } from '../../models/notes/notes.models';
import { getAttachment, getAttachmentsList, getNotesList } from '../../api/notes/notes.api';

export function* fetchAssessmentsListSaga() {
	try {
		yield call(getAssessmentsList);
	} catch (e) {
		handleGlobalException(e);
	}
}

function* getPatientInfoSaga(opportunitySfid: string) {
	try {
		yield put(actions.assessments.getPatientInfoIsLoading(true));
		const result: IPatientInfo = yield call(getPatientInfo, { opportunitySfid });
		if (result) {
			yield put(actions.assessments.setPatientInfo({ patientInfo: result }));
		}
	} catch (e) {
		handleGlobalException(e);
	} finally {
		yield put(actions.assessments.getPatientInfoIsLoading(false));
	}
}

function* getNotesListSaga(opportunitySfid: string) {
	try {
		yield put(actions.assessments.getNotesListIsLoading(true));
		const result: INotesListItem[] = yield call(getNotesList, { opportunitySfid });
		if (result) {
			yield put(actions.assessments.setNotesList({ notes: result }));
		}
	} catch (e) {
		handleGlobalException(e);
	} finally {
		yield put(actions.assessments.getNotesListIsLoading(false));
	}
}

function* getAttachmentsListSaga(opportunitySfid: string) {
	try {
		yield put(actions.assessments.getAttachmentsListIsLoading(true));
		const result: IAttachmentsListItem[] = yield call(getAttachmentsList, { opportunitySfid });
		if (result) {
			yield put(actions.assessments.setAttachmentsListAction({ attachments: result }));
		}
	} catch (e) {
		handleGlobalException(e);
	} finally {
		yield put(actions.assessments.getAttachmentsListIsLoading(false));
	}
}

export function* getAttachmentSaga(action: IAction<IGetAttachmentActionPayload>) {
	try {
		yield put(actions.assessments.getAttachmentIsLoading(true));
		const { id, download, preview } = action.payload;
		const payload = {
			id,
			download,
			preview,
		};
		const result: Blob = yield call(getAttachment, payload);
		if (result) {
			if (preview) {
				yield put(actions.assessments.setAttachmentPreviewAction({ attachment: result }));
			}
			if (download) {
				yield put(actions.assessments.setAttachmentDownloadAction({ attachment: result }));
			}
		}
	} catch (e) {
		handleGlobalException(e);
	} finally {
		yield put(actions.assessments.getAttachmentIsLoading(false));
	}
}

export function* getOpportunitySaga(action: IAction<IGetOpportunityActionPayload>) {
	try {
		yield put(actions.assessments.getOpportunityIsLoading(true));
		const result: IOpportunityItem = yield call(getSfOpportunities, { id: action.payload.id });
		if (result) {
			yield put(actions.assessments.setOpportunity({ opportunity: result }));
			if (result.sfid) {
				yield fork(getPatientInfoSaga, result.sfid);
				yield fork(getNotesListSaga, result.sfid);
				yield fork(getAttachmentsListSaga, result.sfid);
			}
		}
	} catch (e) {
		handleGlobalException(e);
	} finally {
		yield put(actions.assessments.getOpportunityIsLoading(false));
	}
}
