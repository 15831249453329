import { IAttachmentsListResponse, INotesListResponse } from '../../models/notes/notes.models';
import { getBlobRequest, getRequest } from '../api-service';
import { endpoints } from '../endpoints';

export function getNotesList(request: { opportunitySfid: string }): Promise<INotesListResponse> {
	const body = {
		linkedEntityId: request.opportunitySfid,
	};
	const endpoint = endpoints.assessment.notes;
	return getRequest<INotesListResponse>(endpoint, body);
}

export function getAttachmentsList(request: { opportunitySfid: string }): Promise<IAttachmentsListResponse> {
	const body = {
		opportunitySfid: request.opportunitySfid,
	};
	const endpoint = endpoints.assessment.attachments;
	return getRequest<IAttachmentsListResponse>(endpoint, body, {
		ignoreIdPath: true,
	});
}

export async function getAttachment(request: { id: string; download: boolean; preview: boolean }): Promise<Blob> {
	const body = {
		id: request.id,
		download: request.download,
		preview: request.preview,
	};
	const endpoint = endpoints.assessment.attachments;
	const result = await getBlobRequest(endpoint, body);
	return result;
}
