import { call, put } from 'redux-saga/effects';
import { environment } from '../../../environment/environment';
import { getIsAuthenticatedCache, setBearerCache, setIsAuthenticatedCache } from '../../../system/local-storage';
import { decrypt, checkExpiredJWT } from '../../../utils/api.utils';
import { endpoints } from '../../api/endpoints';
import { routes } from '../../../pages/App/routes';
import { push } from '../../../system/navigator';
import { getAuthenticatedUsername } from '../../api/salesforce/salesforce.api';
import { IGetAuthenticatedUsernameResponse } from '../../models/salesforce/salesforce.models';
import { actions } from '../actions';

async function getLoginToken() {
	return fetch(endpoints.myPAT.userToken, {
		credentials: 'include',
	})
		.then((response) => response.json())
		.then((data) => data.token);
}

function* setAuthenticatedStatus(authenticated: boolean) {
	yield call(setIsAuthenticatedCache, authenticated);
}

function* setCachedBearerToken(loginToken: string) {
	const { DECRYPTION_KEY } = environment;
	yield call(setBearerCache, decrypt(loginToken, DECRYPTION_KEY));
}

function* redirectToLogin() {
	yield window.location.replace(endpoints.myPAT.login);
}

export function* initAppSaga() {
	const isAuthenticated: boolean = yield call(getIsAuthenticatedCache);
	const usernameResponse: IGetAuthenticatedUsernameResponse = yield call(getAuthenticatedUsername);
	if (!isAuthenticated || checkExpiredJWT(usernameResponse)) {
		const loginToken: string = yield call(getLoginToken);
		if (loginToken) {
			yield call(setAuthenticatedStatus, true);
			yield call(setCachedBearerToken, loginToken);
			push(routes.homeTabs.assessments);
		} else {
			yield call(redirectToLogin);
		}
	} else if (usernameResponse) {
		yield put(actions.user.setUsernameAction({ name: usernameResponse.name, username: usernameResponse.username }));
	}
}
