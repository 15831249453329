import * as React from 'react';
import { Tab, Tabs, Box } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import PatientAndMedicationReview from '../../../pages/Assessments/Steps/PatientAndMedicationReview';
import NotesAttachments from '../notes-attachments/NotesAttachments';
import { IAttachmentsListItem, INotesListItem } from '../../../core/models/notes/notes.models';

interface TabPanelProps {
	children?: React.ReactNode;
	index: number;
	value: number;
}

function TabPanel(props: TabPanelProps) {
	const { children, value, index } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			style={{ width: '100%' }}
			id={`vertical-tabpanel-${index}`}
			aria-labelledby={`vertical-tab-${index}`}
		>
			{value === index && <Box sx={{ padding: '30px' }}>{children}</Box>}
		</div>
	);
}

function a11yProps(index: number) {
	return {
		id: `vertical-tab-${index}`,
		'aria-controls': `vertical-tabpanel-${index}`,
	};
}

interface Step {
	id: number;
	title: string;
	content: string | React.ReactNode;
}

const steps: Step[] = [
	{
		id: 0,
		title: 'Patient and Medication Review',
		content: <PatientAndMedicationReview />,
	},
	{
		id: 1,
		title: 'Health Review',
		content: 'Health Review Content',
	},
	{
		id: 2,
		title: 'Forms',
		content: 'Forms Content',
	},
	{
		id: 3,
		title: 'MyECP and Wrap-Up',
		content: 'MyECP and Wrap-Up Content',
	},
	{
		id: 4,
		title: 'Finalize and Submit',
		content: 'Finalize and Submit Content',
	},
];

interface IAssessmentsSidebarProps {
	notes: INotesListItem[];
	attachments: IAttachmentsListItem[];
	attachmentPreviewBlob?: Blob;
	attachmentDownloadBlob?: Blob;
	blobIsLoading: boolean;
	notesAndAttachmentsIsLoading: boolean;
	onOpenAttachment: (id: string, preview: boolean, download: boolean) => void;
}

export default function AssessmentsSidebar({
	notes,
	attachmentPreviewBlob,
	attachmentDownloadBlob,
	blobIsLoading,
	attachments,
	notesAndAttachmentsIsLoading,
	onOpenAttachment,
}: IAssessmentsSidebarProps) {
	const [value, setValue] = React.useState(0);

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setValue(newValue);
	};

	return (
		<Box sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex' }}>
			<Tabs
				orientation="vertical"
				variant="fullWidth"
				textColor="secondary"
				value={value}
				onChange={handleChange}
				sx={{ borderRight: 1, borderColor: 'divider', minWidth: 320 }}
			>
				{steps.map((step, index) => (
					<Tab
						key={step.id}
						icon={<CheckCircleOutlineIcon />}
						disableRipple
						wrapped
						iconPosition="start"
						label={step.title}
						sx={{ justifyContent: 'left', fontSize: '0.75rem' }}
						id={a11yProps(index).id}
						aria-controls={a11yProps(index)['aria-controls']}
					/>
				))}
				<NotesAttachments
					notes={notes}
					attachments={attachments}
					attachmentPreviewBlob={attachmentPreviewBlob}
					attachmentDownloadBlob={attachmentDownloadBlob}
					blobIsLoading={blobIsLoading}
					loading={notesAndAttachmentsIsLoading}
					onOpenAttachment={onOpenAttachment}
				/>
			</Tabs>
			{steps.map((step, index) => (
				<TabPanel key={step.id} value={value} index={index}>
					{step.content}
				</TabPanel>
			))}
		</Box>
	);
}
