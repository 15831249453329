import { createReducer } from '@reduxjs/toolkit';
import { assessmentsActions } from './assessments.actions';
import { IOpportunityItem, IPatientInfo } from '../../models/assessments/assessments.models';
import { IAttachmentsListItem, INotesListItem } from '../../models/notes/notes.models';

export interface AssessmentState {
	patientInfo?: IPatientInfo;
	opportunity?: IOpportunityItem;
	isReadonly: boolean;
	notes: INotesListItem[];
	attachments: IAttachmentsListItem[];
	attachmentPreview?: Blob;
	attachmentDownload?: Blob;
	attachmentIsLoading: boolean;
	opportunityIsLoading: boolean;
	patientInfoIsLoading: boolean;
	notesIsLoading: boolean;
	attachmentsIsLoading: boolean;
}

const initialState: AssessmentState = {
	patientInfo: undefined,
	opportunity: undefined,
	notes: [],
	attachments: [],
	attachmentPreview: undefined,
	attachmentDownload: undefined,
	isReadonly: false,
	opportunityIsLoading: false,
	patientInfoIsLoading: false,
	notesIsLoading: false,
	attachmentsIsLoading: false,
	attachmentIsLoading: false,
};

const assessmentsReducer = createReducer(initialState, (builder) => {
	builder
		.addCase(assessmentsActions.setPatientInfo, (state, action) => {
			state.patientInfo = action.payload.patientInfo;
		})
		.addCase(assessmentsActions.getPatientInfoIsLoading, (state, action) => {
			state.patientInfoIsLoading = action.payload;
		})
		.addCase(assessmentsActions.getOpportunityIsLoading, (state, action) => {
			state.opportunityIsLoading = action.payload;
		})
		.addCase(assessmentsActions.setOpportunity, (state, action) => {
			state.opportunity = action.payload.opportunity;
		})
		.addCase(assessmentsActions.setNotesList, (state, action) => {
			state.notes = action.payload.notes;
		})
		.addCase(assessmentsActions.getNotesListIsLoading, (state, action) => {
			state.notesIsLoading = action.payload;
		})
		.addCase(assessmentsActions.setAttachmentsListAction, (state, action) => {
			state.attachments = action.payload.attachments;
		})
		.addCase(assessmentsActions.getAttachmentsListIsLoading, (state, action) => {
			state.attachmentsIsLoading = action.payload;
		})
		.addCase(assessmentsActions.setAttachmentPreviewAction, (state, action) => {
			state.attachmentPreview = action.payload.attachment;
		})
		.addCase(assessmentsActions.setAttachmentDownloadAction, (state, action) => {
			state.attachmentDownload = action.payload.attachment;
		})
		.addCase(assessmentsActions.getAttachmentIsLoading, (state, action) => {
			state.attachmentIsLoading = action.payload;
		});
});

export default assessmentsReducer;
