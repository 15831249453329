import { createBrowserHistory } from 'history';

export const navigator = createBrowserHistory();

export const push = (to: string) => {
	navigator.push(to);
};

export const replace = (to: string) => {
	navigator.replace(to);
};
