import createSagaMiddleware from 'redux-saga';
import { configureStore } from '@reduxjs/toolkit';
import userReducer from './state/user/user.reducer';
import assessmentsReducer from './state/assessments/assessments.reducer';

export const allReducers = {
	user: userReducer,
	assessments: assessmentsReducer,
};

export const sagaMiddleware = createSagaMiddleware();

export const reduxStore = configureStore({
	reducer: allReducers,
	middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(sagaMiddleware),
	devTools: process.env.NODE_ENV !== 'production',
});
