import { createAction } from '@reduxjs/toolkit';
import { assessmentsConstants } from './assessments.constants';
import { IOpportunityItem, IPatientInfo } from '../../models/assessments/assessments.models';
import { IAttachmentsListItem, INotesListItem } from '../../models/notes/notes.models';

const getAssessmentsListAction = createAction(assessmentsConstants.GET_ASSESSSMENTS_LIST);

export interface IGetPatientInfoActionPayload {
	id: string;
}

const getPatientInfoAction = createAction<IGetPatientInfoActionPayload>(assessmentsConstants.GET_PATIENT_INFO);
const getPatientInfoIsLoading = createAction<boolean>(assessmentsConstants.GET_PATIENT_INFO_IS_LOADING);

interface ISetPatientInfoActionPayload {
	patientInfo: IPatientInfo;
}

const setPatientInfo = createAction<ISetPatientInfoActionPayload>(assessmentsConstants.SET_PATIENT_INFO);

// Opportunity
export interface IGetOpportunityActionPayload {
	id: string;
}

const getOpportunityAction = createAction<IGetOpportunityActionPayload>(assessmentsConstants.GET_OPPORTUNITY);
const getOpportunityIsLoading = createAction<boolean>(assessmentsConstants.GET_OPPORTUNITY_IS_LOADING);

export interface ISetOpportunityActionPayload {
	opportunity: IOpportunityItem;
}
const setOpportunity = createAction<ISetOpportunityActionPayload>(assessmentsConstants.SET_OPPORTUNITY);

// Notes and Attachments

export interface IGetNotesListActionPayload {
	id: string;
}

const getNotesListAction = createAction<IGetNotesListActionPayload>(assessmentsConstants.GET_NOTES_LIST);
const getNotesListIsLoading = createAction<boolean>(assessmentsConstants.GET_NOTES_LIST_IS_lOADING);

export interface ISetNotesListActionPayload {
	notes: INotesListItem[];
}

const setNotesList = createAction<ISetNotesListActionPayload>(assessmentsConstants.SET_NOTES_LIST);

export interface IGetAttachmentsListActionPayload {
	id: string;
}

const getAttachmentsListAction = createAction<IGetAttachmentsListActionPayload>(
	assessmentsConstants.GET_ATTACHMENTS_LIST
);
const getAttachmentsListIsLoading = createAction<boolean>(assessmentsConstants.GET_ATTACHMENTS_LIST_IS_LOADING);

export interface ISetAttachmentsListActionPayload {
	attachments: IAttachmentsListItem[];
}

const setAttachmentsListAction = createAction<ISetAttachmentsListActionPayload>(
	assessmentsConstants.SET_ATTACHMENTS_LIST
);

export interface IGetAttachmentActionPayload {
	id: string;
	download: boolean;
	preview: boolean;
}

const getAttachmentAction = createAction<IGetAttachmentActionPayload>(assessmentsConstants.GET_ATTACHMENT);
const getAttachmentIsLoading = createAction<boolean>(assessmentsConstants.GET_ATTACHMENT_IS_LOADING);

export interface ISetAttachmentActionPayload {
	attachment: Blob;
}

const setAttachmentPreviewAction = createAction<ISetAttachmentActionPayload>(
	assessmentsConstants.SET_ATTACHMENT_PREVIEW
);
const setAttachmentDownloadAction = createAction<ISetAttachmentActionPayload>(
	assessmentsConstants.SET_ATTACHMENT_DOWNLOAD
);

export const assessmentsActions = {
	getAssessmentsListAction,
	getPatientInfoAction,
	getPatientInfoIsLoading,
	setPatientInfo,

	getOpportunityAction,
	getOpportunityIsLoading,
	setOpportunity,

	getNotesListAction,
	getNotesListIsLoading,
	setNotesList,

	getAttachmentsListAction,
	getAttachmentsListIsLoading,
	setAttachmentsListAction,

	getAttachmentAction,
	getAttachmentIsLoading,
	setAttachmentPreviewAction,
	setAttachmentDownloadAction,
};
