import { all, call, takeLatest } from 'redux-saga/effects';
import { constants } from './state/constants';
import * as saga from '.';

function* actionWatcher() {
	// App
	yield takeLatest(constants.app.INIT_APP, saga.initAppSaga);

	// Assessment
	yield takeLatest(constants.assessments.GET_ASSESSSMENTS_LIST, saga.fetchAssessmentsListSaga);
	// yield takeLatest(constants.assessments.GET_PATIENT_INFO, saga.getPatientInfoSaga);
	yield takeLatest(constants.assessments.GET_OPPORTUNITY, saga.getOpportunitySaga);

	// Notes and Attachment
	yield takeLatest(constants.assessments.GET_ATTACHMENT, saga.getAttachmentSaga);
}

export default function* rootSaga() {
	yield all([call(actionWatcher)]);
}
