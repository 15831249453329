import React from 'react';
import {
	Box,
	Typography,
	Grid,
	Button,
	TextField,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	LinearProgress,
} from '@mui/material';
import dayjs from 'dayjs';
import { useTheme } from '@mui/material/styles';
import { IOpportunityItem, IPatientInfo } from '../../../core/models/assessments/assessments.models';
import { acronymFilter } from '../../../utils/app.utils';
import { environment } from '../../../environment/environment';

const accountManager = {
	name: 'John Smith',
};

const communityLiaison = {
	name: 'Jane Doe',
};

function AssessmentsHeader({
	isLoading,
	patientInfo,
	opportunity,
}: {
	isLoading: boolean;
	patientInfo?: IPatientInfo;
	opportunity?: IOpportunityItem;
}) {
	const theme = useTheme();
	const { SALESFORCE_URL } = environment;
	const salesforceOpportunityPath = `${SALESFORCE_URL}apex/Opportunity_Detail?id=${opportunity?.sfid}`;
	const [open, setOpen] = React.useState(false);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	if (isLoading) {
		return (
			<Box sx={{ padding: '20px', borderBottom: '1px solid', borderColor: theme.palette.border.main }}>
				<LinearProgress />
			</Box>
		);
	}

	return (
		<Box sx={{ padding: '20px', borderBottom: '1px solid', borderColor: theme.palette.border.main }}>
			<Box sx={{ display: 'flex', alignItems: 'center' }}>
				{/* Referral Information and Services Needed */}
				{/* Patient Info */}
				<Grid container sx={{ flexWrap: 'unset' }}>
					<Grid
						item
						sx={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						<Box
							bgcolor="primary.main"
							color="white"
							borderRadius="50%"
							mr={2}
							width={60}
							height={60}
							display="flex"
							justifyContent="center"
							alignItems="center"
						>
							<Typography variant="h6">{patientInfo && acronymFilter(patientInfo.patientFullName)}</Typography>
						</Box>
					</Grid>
					<Grid item>
						<Box>
							<Typography variant="h6" component="div">
								<a href={salesforceOpportunityPath} target="_blank" rel="noopener noreferrer">
									{patientInfo?.patientFullName}
								</a>
								<Box
									component="span"
									sx={{ fontSize: '12px' }}
									bgcolor={patientInfo?.hasCMR ? 'warning.main' : 'error.main'}
									color="white"
									ml={1}
									p={0.5}
								>
									{patientInfo?.hasCMR ? 'CMR' : 'HEDIS'}
								</Box>
							</Typography>
							{/* Rest of the patient details */}
							<Typography variant="body2">
								Address: {patientInfo?.shippingAddress} {patientInfo?.shippingCity} {patientInfo?.shippingState}{' '}
								{patientInfo?.shippingZipCode}
							</Typography>
							<Typography variant="body2">
								Mobile: {patientInfo?.primaryPhone} / Home: {patientInfo?.alternatePhone} / DOB:{' '}
								{patientInfo?.dateOfBirth ? dayjs(patientInfo?.dateOfBirth).format('MM/DD/YYYY') : '-'}
							</Typography>
							<Typography variant="body2">
								Territory: {opportunity?.patientTerritory} / CL Manager: {accountManager.name} / Clinical Liaison:{' '}
								{communityLiaison.name}
							</Typography>
							<Typography variant="body2">Insurance Eligibility Status: {opportunity?.insuranceEligibility}</Typography>
						</Box>
					</Grid>
				</Grid>
				<Grid container spacing={2} sx={{ borderLeft: '5px solid', borderColor: theme.palette.secondary.main }}>
					<Grid item xs={12} sm={6}>
						<Typography variant="subtitle2">Referral Information</Typography>
						<Typography variant="body2">Account: {patientInfo?.referringAccount}</Typography>
						<Typography variant="body2">Name: </Typography> {/* referred contact call needed */}
					</Grid>
					<Grid item xs={12} sm={6} sx={{ borderLeft: '5px solid', borderColor: theme.palette.secondary.main }}>
						<Typography variant="subtitle2">Services Needed</Typography>
						<Typography variant="body2">CMR</Typography>
					</Grid>
				</Grid>
			</Box>
			<Grid container justifyContent="flex-end" alignItems="center" spacing={1}>
				<Grid item>
					<Button variant="outlined" onClick={handleClickOpen}>
						Log a Contact Call
					</Button>
				</Grid>
			</Grid>
			{/* Modal */}
			<Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
				<DialogTitle>Completion Pending</DialogTitle>
				<DialogContent>
					<TextField fullWidth select label="Completing pending" variant="outlined" margin="normal">
						{/* option items here */}
					</TextField>
					<TextField
						fullWidth
						label="Follow Up Date"
						type="date"
						variant="outlined"
						margin="normal"
						InputLabelProps={{
							shrink: true,
						}}
					/>
					<TextField fullWidth label="Notes" variant="outlined" margin="normal" />
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color="primary" variant="contained">
						Save Assessment
					</Button>
					{/* <Button onClick={handleClose} color="default"></Button> */}
				</DialogActions>
			</Dialog>
		</Box>
	);
}

AssessmentsHeader.defaultProps = {
	patientInfo: {
		shippingZipCode: '-',
	},
};

export default AssessmentsHeader;
