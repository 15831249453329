import * as React from 'react';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import {
	Button,
	Box,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Paper,
	Typography,
	useTheme,
	CircularProgress,
} from '@mui/material';
import { IAttachmentsListItem, INotesListItem } from '../../../core/models/notes/notes.models';

interface INotesAttachmentsDialogProps {
	open: boolean;
	onClose: () => void;
	onDownload: (id: string) => void;
	openedNoteItem: INotesListItem | null;
	openedAttachmentItem: IAttachmentsListItem | null;
	attachmentPreviewBlob?: Blob;
	attachmentDownloadBlob?: Blob;
	blobIsLoading: boolean;
}

interface IRenderNotesDialogContentProps {
	note: INotesListItem;
	onClose: () => void;
}

function RenderNotesDialogContent({ note, onClose }: IRenderNotesDialogContentProps) {
	const theme = useTheme();

	return (
		<Paper>
			<DialogTitle align="center" sx={{ pb: 0 }}>
				{note.title}
			</DialogTitle>

			<Box sx={{ display: 'flex', justifyContent: 'center', mt: 0 }}>
				<Typography variant="overline" align="center" sx={{ mt: 0 }}>
					Last modified: {dayjs(note.lastModifiedDate).format('MMM D, YYYY h:mm A [EDT]')}
				</Typography>
			</Box>
			<DialogContent sx={{ borderTop: 1, borderColor: theme.palette.divider }}>{note.note.title}</DialogContent>
			<DialogActions>
				<Button variant="outlined" onClick={onClose}>
					Close
				</Button>
			</DialogActions>
		</Paper>
	);
}

function BlobDisplayComponent({ blob }: { blob: Blob }) {
	const [blobUrl, setBlobUrl] = useState<string | null>(null);

	useEffect(() => {
		// Create object URL and clean up on component unmount
		const url = URL.createObjectURL(blob);
		setBlobUrl(url);
		return () => {
			URL.revokeObjectURL(url);
		};
	}, [blob]);

	if (blobUrl && blob.type.includes('image')) {
		// Display blob as image
		return <img src={blobUrl} alt="" />;
	}
	if (blobUrl && blob.type.includes('pdf')) {
		// Display blob as PDF
		return <iframe title="Blob as PDF" src={blobUrl} style={{ width: '100%', height: '70vh' }} />;
	}
	return <p>Unsupported blob type</p>;
}

interface IRenderAttachmentDialogContentProps {
	attachment: IAttachmentsListItem;
	onClose: () => void;
	onDownload: () => void;
	blob?: Blob;
	blobIsLoading: boolean;
}

function RenderAttachmentDialogContent({
	attachment,
	blob,
	blobIsLoading,
	onClose,
	onDownload,
}: IRenderAttachmentDialogContentProps) {
	return (
		<Box>
			<DialogTitle align="center" sx={{ pb: 0 }}>
				{attachment.title}
			</DialogTitle>

			<Box sx={{ display: 'flex', justifyContent: 'center', mt: 0 }}>
				<Typography variant="overline" align="center" sx={{ mt: 0 }}>
					Last modified: {dayjs(attachment.lastModifiedDate).format('MMM D, YYYY h:mm A [EDT]')}
				</Typography>
			</Box>
			{blobIsLoading ? (
				<DialogContent>
					<Box sx={{ display: 'flex', justifyContent: 'center' }}>
						<CircularProgress />
					</Box>
				</DialogContent>
			) : (
				<DialogContent sx={{ height: '100%' }}>{blob && <BlobDisplayComponent blob={blob} />}</DialogContent>
			)}
			<DialogActions>
				<Button variant="contained" onClick={onDownload}>
					Download
				</Button>
				<Button variant="outlined" onClick={onClose}>
					Close
				</Button>
			</DialogActions>
		</Box>
	);
}

export default function NotesAttachmentsDialog({
	open,
	openedNoteItem,
	openedAttachmentItem,
	attachmentPreviewBlob,
	attachmentDownloadBlob,
	blobIsLoading,
	onClose,
	onDownload,
}: INotesAttachmentsDialogProps) {
	const handleDownload = () => {
		if (openedAttachmentItem) {
			onDownload(openedAttachmentItem.id);
		}
	};

	useEffect(() => {
		let url: string | null = null;
		if (attachmentDownloadBlob && openedAttachmentItem) {
			url = window.URL.createObjectURL(attachmentDownloadBlob);
			const link = document.createElement('a');
			const { title, fileExtension } = openedAttachmentItem;
			link.href = url;
			link.setAttribute('download', `${title}.${fileExtension}`);
			document.body.appendChild(link);
			link.click();
			if (link.parentNode) {
				link.parentNode.removeChild(link);
			}
		}
		return () => {
			if (url) URL.revokeObjectURL(url);
		};
	}, [attachmentDownloadBlob]);

	return (
		<Dialog sx={{ '& .MuiDialog-paper': { minWidth: '1024px' } }} maxWidth={false} open={open} onClose={onClose}>
			{openedNoteItem && <RenderNotesDialogContent note={openedNoteItem} onClose={onClose} />}
			{openedAttachmentItem && (
				<RenderAttachmentDialogContent
					attachment={openedAttachmentItem}
					onClose={onClose}
					onDownload={handleDownload}
					blob={attachmentPreviewBlob}
					blobIsLoading={blobIsLoading}
				/>
			)}
		</Dialog>
	);
}
