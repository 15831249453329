export enum AssessmentHeadings {
	headings = 'headings',
	opportunityName = 'opportunity name',
	scheduledDate = 'scheduled date',
	submittedDate = 'submitted date',
	opportunityStage = 'opportunity stage',
	schedulingStatus = 'scheduling status',
	phone = 'phone',
	shippingStreet = 'shipping street',
	shippingCity = 'shipping city',
	shippingState = 'shipping state',
	accountManager = 'account manager',
	territory = 'territory',
	referringName = 'referring name',
	referringAccount = 'referring account',
	keyInstructions = 'key instructions',
}

export interface IAssessmentItem {
	'@id': string;
	'@type': string;
	id: number;
	sfid: string;
	name: string;
	scheduledDate: string;
	submittedDate: string | null;
	stage: string;
	schedulingStatus: string;
	phone: string | null;
	shippingStreet: string;
	shippingCity: string;
	shippingState: string;
	accountManager: string;
	territory: string;
	referrerContactSfid: string | null;
	referringAccount: string;
	keyInstructions: string | null;
	webReferralId: string | null;
	myecpOnboardingStatus: string;
	completionPendingReason: string;
	leadSource: string | null;
	drugTestBrandCost: string | null;
	drugTestGenericCost: string | null;
	vials: boolean;
	referringName: string | null;
}

interface ContextType {
	'@context': string;
	'@id': string;
	'@type': string;
}

export interface IOpportunityItem extends ContextType {
	id: number;
	accountSfid: string;
	sfid: string;
	recordTypeSfid: string;
	name: string;
	firstName: string;
	lastName: string;
	dateOfBirth: null;
	gender: null;
	patientName: string;
	height: null;
	weight: null;
	ssn: null;
	shippingStreet: null;
	shippingCity: null;
	shippingState: null;
	shippingZip: null;
	billingStreet: null;
	billingCity: null;
	billingState: null;
	billingZip: null;
	firstScriptBilled: string;
	contactPreference: null;
	allergicReactions: string;
	referrerContactSfid: null;
	referringAccountSfid: null;
	referringPhone: string;
	daysDatesTimes: boolean;
	time: boolean;
	dates: boolean;
	largePrint: boolean;
	spanish: boolean;
	splitPack: boolean;
	rubberbands: boolean;
	insuranceEligibility: string;
	insuranceEligibilityNotes: null;
	primaryInsuranceCompanyName: null;
	primaryBin: null;
	primaryGroup: null;
	primaryPcn: null;
	primaryInsuranceId: null;
	secondaryInsuranceCompanyName: null;
	secondaryBin: null;
	secondaryGroup: null;
	secondaryPcn: null;
	secondaryInsuranceId: null;
	medicaidId: null;
	primaryPhone: null;
	alternatePhone: null;
	email: null;
	keyInstructions: null;
	schedulingStatus: string;
	stageName: string;
	submittedDate: string;
	submittedBy: string;
	scheduledDate: string;
	selfHealthDecisions: null;
	formsSubmitted: boolean;
	hedisFormsSubmitted: boolean;
	assessmentStatus: string;
	mypaProgressSteps: string;
	patientWillSendPoA: boolean;
	assessmentScheduledFor: string;
	accountManagerSfid: null;
	phoneFollowUp: null;
	patientExcitementLevel: null;
	assessmentInformationProvidedBy: string;
	signatureRequired: boolean;
	easyOpenVialCaps: boolean;
	schedulingCallBackDate: null;
	schedulingCallBackNotes: null;
	inactivePointInProcess: null;
	inactiveReasonSource: null;
	inactiveReason: null;
	inactiveReasonDetails: null;
	inactiveReasonNotes: null;
	inactivatedByUserSfid: null;
	inactivateDate: string;
	inactiveAtStage: null;
	rescheduleRequestDate: null;
	reschedulingReason: null;
	medicationSummary: null;
	pharmacyTechnicianSfid: null;
	groupHomeMarRequired: boolean;
	herokuConnectSyncError: string;
	eligibleForTips: boolean;
	eligibleForMtm: boolean;
	eligibleForCMR: boolean;
	eligibleMedicationsForAdherenceCheck: null;
	clMinutesSpentWithPatient: null;
	active: string;
	cmrReadyTime: null;
	cmrScheduledDate: null;
	cmrScheduledTime: null;
	cmrDeliveryMethod: null;
	listAffiliateName: null;
	okForTransfers: boolean;
	transferStatus: string;
	awaitingTransferDate: string;
	medLiteratureFrequency: null;
	assessmentMethod: string;
	webReferralId: null;
	myecpOnboardingStatus: null;
	patientTerritory: null;
	completionPendingReason: null;
	profilePrepAction: null;
}

interface AlternateAddress extends ContextType {
	accountAlternateAddressId: number;
	name: string;
	street: string;
	city: string;
	state: string;
	zipCode: string;
}

export interface IPatientInfo extends ContextType {
	opportunitySfid: string;
	patientFullName: string;
	firstName: string;
	lastName: string;
	middleName: string;
	dateOfBirth: string;
	ssn: string;
	gender: string;
	height: string;
	weight: string;
	billingAddressIsSameAsShippingAddress: boolean;
	shippingAddress: string;
	shippingCity: string;
	shippingState: string;
	shippingZipCode: string;
	billingAddress: string;
	billingCity: string;
	billingState: string;
	billingZipCode: string;
	primaryPhone: string;
	alternatePhone: string;
	email: string;
	contactPreference: string[];
	allergicReactions: string;
	referrerName: string;
	referringAccount: string;
	referringPhone: string;
	signatureRequired: boolean;
	easyOpenVialCaps: boolean;
	alternateAddresses: AlternateAddress[];
	accountGuid: string;
	hasCMR: boolean;
	preferredContactDays: string[];
	preferredContactTime: string[];
}
