import * as React from 'react';
import { Box } from '@mui/material';
import AssessmentsStepper from '../../../components/assessments/assessments-stepper/AssessmentsStepper';

export default function PatientAndMedicationReview() {
	return (
		<Box>
			<AssessmentsStepper />
		</Box>
	);
}
