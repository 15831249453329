import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import './index.css';
import App from './pages/App/App';
import { reduxStore, sagaMiddleware } from './core/reducers';
import rootSaga from './core/sagas';
import CustomRouter from './components/router/CustomRouter';
import reportWebVitals from './utils/reportWebVitals';
import { navigator } from './system/navigator';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
	<Provider store={reduxStore}>
		<CustomRouter history={navigator}>
			<App />
		</CustomRouter>
	</Provider>
);
sagaMiddleware.run(rootSaga);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
